<template>
  <SfSidebar
    :visible="isWishlistOpen && isWishlistVisible"
    class="sf-sidebar--right sf-sidebar--icon o-microcart-panel"
    @close="closeWishlist"
  >
    <template #content-top>
      <div class="o-microcart-panel__title">
        {{ $t('Wishlist') }}
      </div>
    </template>
    <transition name="fade" mode="out-in">
      <div v-if="totalItems" key="my-wishlist" class="my-wishlist">
        <MCollectedProductsGroup
          :products="productsInWishlist"
          :has-quantity-selector="false"
          @click:add-to-cart="addToCart"
          @click:remove="removeProductHandler"
        />
      </div>
      <div v-else key="empty-cart" class="empty-cart">
        <div class="empty-cart__banner">
          <SfImage
            :src="'/assets/empty_cart.svg'"
            :alt="$t('Your wishlist is empty')"
            class="empty-cart__image"
          />
          <SfHeading
            :title="$t('Your wishlist is empty')"
            :description="$t('Looks like you haven’t added any items to the wishlist yet. Start browsing to fill it in.')"
            :level="2"
            class="empty-cart__heading"
          />
        </div>
      </div>
    </transition>
    <template #content-bottom>
      <transition name="fade">
        <div>
          <SfButton
            class="sf-button--full-width color-primary wishlit__clear-wishlist"
            @click.native="clearWishlist"
          >
            {{ $t("Clear wishlist") }}
          </SfButton>
          <SfButton
            class="sf-button--full-width color-primary"
            @click.native="startShopping"
          >
            {{ $t("Start shopping") }}
          </SfButton>
        </div>
      </transition>
    </template>
  </SfSidebar>
</template>

<script>
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { onlineHelper } from '@vue-storefront/core/helpers';
import { getThumbnailForProduct, notifications } from '@vue-storefront/core/modules/cart/helpers';
import { getProductPrice, getProductPriceFromTotals } from '~/theme/helpers';
import VueOfflineMixin from 'vue-offline/mixin';
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress';
import MCollectedProductsGroup from '~/theme/components/molecules/m-collected-products-group';
import { Wishlist } from '@vue-storefront/core/modules/wishlist/components/Wishlist';

import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import SfSidebar from '@storefront-ui/vue/src/components/organisms/SfSidebar/SfSidebar.vue';

export default {
  components: {
    SfButton,
    MCollectedProductsGroup,
    SfImage,
    SfHeading,
    SfSidebar
  },
  mixins: [VueOfflineMixin, onEscapePress, Wishlist],
  data () {
    return {
      isWishlistVisible: false,
      isAddingToCart: false
    }
  },
  computed: {
    totalItems () {
      return this.productsInWishlist.length;
    },
    productsCount () {
      let count = 0;
      this.productsInCart.forEach(product => {
        count = count + parseInt(product.qty);
      });
      return count;
    }
  },
  methods: {
    onEscapePress () {
      this.$store.commit('ui/setWishlist', false)
    },
    startShopping () {
      this.$router.push(localizedRoute('/'));
      this.closeWishlist();
    },
    getThumbnailForProductExtend (product) {
      return getThumbnailForProduct(product);
    },
    getProductPrice (product) {
      return onlineHelper.isOnline && product.totals && product.totals.options
        ? getProductPriceFromTotals(product)
        : getProductPrice(product);
    },
    getProductOptions (product) {
      return onlineHelper.isOnline && product.totals && product.totals.options
        ? product.totals.options
        : product.options || {};
    },
    removeFromWishlist (product) {
      this.$store.dispatch('wishlist/removeItem', product)
    },
    clearWishlist () {
      this.productsInWishlist.forEach(product => this.removeFromWishlist(product))
    },
    removeProductHandler (product) {
      this.removeFromWishlist(product);
    },
    async addToCart (product) {
      this.isAddingToCart = true
      try {
        const diffLog = await this.$store.dispatch('cart/addItems', {
          productsToAdd: [product]
        });
        this.isAddingToCart = false
        this.$emit('change')
        diffLog.clientNotifications.forEach(notificationData => {
          this.$store.dispatch(
            'notification/spawnNotification',
            notificationData,
            { root: true }
          );
        });
      } catch (message) {
        this.isAddingToCart = false
        this.$store.dispatch(
          'notification/spawnNotification',
          notifications.createNotification({ type: 'danger', message }),
          { root: true }
        );
      }
    }
  },
  mounted () {
    this.isWishlistVisible = true;
    this.$bus.$emit('wishlist-open', this.productsInWishlist)
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.wishlit__clear-wishlist {
  margin-bottom: var(--spacer-base);
}
.o-microcart-panel {
  @include for-desktop {
    --sidebar-bottom-padding: var(--spacer-base);
    --sidebar-content-padding: var(--spacer-base);
    --sidebar-circle-icon-top: calc(var(--spacer-xs) * 1.2);
  }
  &__title {
    font-size: var(--font-size--lg);
    font-weight: var(--font-weight--medium);
    color: var(--c-secondary);
  }
}
.my-wishlist {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__total-items {
    margin: 0;
  }
  &__total-price {
    --price-font-size: var(--font-size--sm);
    --price-font-weight: var(--font-weight--semibold);
    margin: 0 0 var(--spacer-xs) 0;
  }
}
.empty-cart {
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-margin: 0 0 var(--spacer-base) 0;
  --heading-title-color: var(--c-primary);
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  &__heading {
    padding: 0 var(--spacer-base);
  }
  &__image {
    --image-width: 13.1875rem;
    margin: 0 0 var(--spacer-2xl) 0;
  }
  @include for-desktop {
    --heading-title-font-size: var(--font-size--lg);
  }
}
.collected-product-list {
  flex: 1;
}
::v-deep .sf-collected-product, .collected-product {
  --collected-product-image-background: var(--c-white);
  --collected-product-configuration-display: flex;
  --cp-actions-opacity: 1;
  --collected-product-remove-opacity: 1;
  --collected-product-remove-bottom: calc(var(--spacer-base) * 1.3);
  &:not(:last-child) {
    border-bottom: 1px solid var(--c-light-variant);
  }
  margin: 0 0 var(--spacer-sm) 0;
  @include for-desktop {
    --price-special-font-size: var(--font-size--sm);
    --property-value-font-size: var(--font-size--sm);
    --property-name-font-size: var(--font-size--sm);
  }
  &__title {
    margin: 0 var(--spacer-base) 0 0;
      font-size: var(--font-size--base);
    @include for-desktop {
      font-size: var(--font-size--sm);
    }
  }
  &__configuration {
    margin: 0;
  }
  &__properties {
    margin: var(--spacer-xs) 0 0 0;
  }
  &__quantity-wrapper {
    position: static;
    padding: var(--spacer-sm) 0;
  }
  &__quantity-selector {
    height: 2rem;
  }
  &__actions {
    display: flex;
  }
  &:hover {
    &::after {
      box-shadow: none;
    }
  }
}
.free-shipping {
  padding-bottom: var(--spacer-xs);
}
</style>
